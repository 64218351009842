<template>
  <div>
    <div class="mb-10">
      <span class="text-header">Create an account</span>
      <div class="mt-2">
        <span> Already have an account? </span>
        <span class="text-underline pointer" @click="$router.push({ name: 'SignIn' })"
          >Login Here</span
        >
      </div>
    </div>
    <v-text-field
      label="Enter your email address or mobile number"
      rounded
      filled
      color="grey"
      class="py-0 my-0"
      v-model="emailOrPhoneNumber"
      hide-details
      dense
    ></v-text-field>
    <div class="text-center my-1">
      <div id="recaptcha-container"></div>
    </div>
    <v-btn
      class="sign-up-button my-4 white--text"
      rounded
      color="#00b08d"
      large
      block
      :disabled="!emailOrPhoneNumber || isLoading"
      :loading="isLoading"
      @click="nextSignUpStep()"
    >
      Continue
    </v-btn>

    <v-row align="center" class="divider">
      <v-col><hr /></v-col>
      <v-col cols="auto"> OR </v-col>
      <v-col><hr /></v-col>
    </v-row>
    <!-- facebook -->
    <v-btn
      class="sign-up-button my-4"
      dark
      rounded
      large
      block
      color="#1877F2"
      :loading="isLoading"
      :disabled="isLoading"
      @click="signUpWithFacebook"
    >
      <v-icon class="mr-2"> mdi-facebook </v-icon>
      Continue with Facebook</v-btn
    >
    <!-- google -->
    <v-btn
      class="sign-up-button my-4"
      color="black"
      outlined
      dark
      rounded
      large
      block
      @click="signUpWithGoogle"
      :loading="isLoading"
      :disabled="isLoading"
    >
      <v-row align="center" justify="center">
        <v-col cols="auto">
          <v-img
            contain
            height="16"
            width="16"
            class="pa-0 ma-0"
            :src="require('@/assets/google-icon.svg')"
          />
        </v-col>
        Continue with Google
      </v-row>
    </v-btn>
    <v-btn
      class="sign-up-button my-4"
      dark
      large
      block
      rounded
      :loading="isLoading"
      :disabled="isLoading"
      @click="signUpWithApple"
    >
      <v-icon class="mr-2"> mdi-apple </v-icon>
      Continue with Apple</v-btn
    >

    <div class="text-center mt-8">
      By continuing, you agree to our
      <a href="#" target="_blank">Terms & Conditions</a>
      and
      <a href="#" target="_blank">Privacy Policy</a>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  created() {
    // set the app bar title
    this.setCurrentAppBarTitle("Save your progress");

    //? check beforeEnter conditions first
    this.beforeEnterMiddleware();

    //? init captcha
    this.initReCaptcha();
  },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters(["getCurrentUser", "getChosenPlan", "getCarbonEmissions"]),
    isEmail() {
      return this.emailOrPhoneNumber?.includes("@");
    },
    emailOrPhoneNumber: {
      get: function () {
        return this.$store.getters["getEmailOrPhoneNumber"];
      },
      set: function (newVal) {
        this.$store.commit("setEmailOrPhoneNumber", newVal);
      },
    },
  },
  methods: {
    ...mapActions([
      "signUpOrSignInUsingGoogleProvider",
      "signUpOrSignInUsingFacebookProvider",
      "signUpOrSignInUsingAppleProvider",
      "signUpOrSignInToFirebaseByPhoneNumber",
      "initReCaptcha",
    ]),
    ...mapMutations(["setErrorSnackbarMessage", "setCurrentAppBarTitle"]),
    beforeEnterMiddleware() {
      //? if user already logged in, redirect him to the customer dashboard
      if (this.getCurrentUser) {
        return this.$router.push({ name: "CustomerDashboard" });
      }
      //? if the user didn't calculate his emissions, redirect him to the calculator
      if (!this.getChosenPlan || !this.getCarbonEmissions) {
        return this.$router.push({ name: "Calculator" });
      }
    },
    nextSignUpStep() {
      if (this.isEmail) {
        this.$router.push({ name: "EmailSignUp" });
      } else {
        this.signUpWithPhoneNumber();
      }
    },
    async signUpWithPhoneNumber() {
      this.isLoading = true;
      try {
        await this.signUpOrSignInToFirebaseByPhoneNumber();
        this.$router.push({ name: "PhoneSignUp" });
      } catch (error) {
        this.setErrorSnackbarMessage(error);
      } finally {
        this.isLoading = false;
      }
    },
    async signUpWithGoogle() {
      this.isLoading = true;
      try {
        await this.signUpOrSignInUsingGoogleProvider();
        this.$router.push({ name: "AboutYou" });
      } catch (error) {
        this.setErrorSnackbarMessage(error);
      } finally {
        this.isLoading = false;
      }
    },
    async signUpWithFacebook() {
      this.isLoading = true;
      try {
        await this.signUpOrSignInUsingFacebookProvider();
        this.$router.push({ name: "AboutYou" });
      } catch (error) {
        this.setErrorSnackbarMessage(error);
      } finally {
        this.isLoading = false;
      }
    },
    async signUpWithApple() {
      this.isLoading = true;
      try {
        await this.signUpOrSignInUsingAppleProvider();
        this.$router.push({ name: "AboutYou" });
      } catch (error) {
        this.setErrorSnackbarMessage(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped>
.divider > div > hr {
  height: 0.5px;
  background-color: #333333;
  border: 0px;
}
.divider {
  margin: 24px 0px;
  font-family: "League Spartan", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: #333333;
}
</style>